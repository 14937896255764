import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Service from "../../components/Services";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function SoleProprietorship() {
  // banner Slider
  // const SoleSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Sole Proprietorship Company Registration`,
  //     content: `Apply for Sole Proprietorship Online Registration with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/sole-slider.png",
  //     alt_tag: "Best Online Sole Proprietorship Registration Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 100,
  //     marginBottom: 70,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Sole Proprietorship Company Registration`,
  //     content: `Best Sole Proprietorship Online Registration Platform`,
  //     image: "/imgs/business/sliders/slider-sole.png",
  //     alt_tag: "Best Online Sole Proprietorship Registration Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Sole Proprietorship Company Registration Service`,
  //     content: `Fast and Secure Sole Proprietorship Online Registration`,
  //     image: "/imgs/business/sliders/sole-sliders.png",
  //     alt_tag: "Best Online Sole Proprietorship Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Sole Proprietorship Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6499`,
    f_price: `8999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const SprAboutData = {
    title: `Sole Proprietorship Registration in India`,
    sub_title: `Sole Proprietorship Company Registration in just 7 Days`,
    paragraph: `One of the most traditional and straightforward business
    structures in India is the sole proprietorship registration.
    One of the simplest, most convenient, and easiest ways to
    start a business is as a single proprietorship, which may be
    started by registering online. Due to the fact that a sole
    proprietorship retains the owner's identity, both the
    business and the owner have the same legal standing. The
    simplest type of business to run is a sole proprietorship
    because there are no specific laws, rules, or regulations
    that apply to sole proprietorship business registration
    online in India. Through TODAYFILINGS, you can register a
    proprietorship business online.`,
    header: `Our Features in Proprietorship Company in India`,
    points: [
      `Minimal Formality`,
      `Unlimited Liability`,
      `Profit`,
      `Simplicity`,
    ],
  };
  /* Service Data */
  const Sole = {
    header: `Advantages of Sole Proprietorship Registration`,
    content: ` One of the most traditional and straightforward business
    structures in India is the sole proprietorship registration.`,
    image: '/imgs/gifs/sole-gif.gif',
    alt_img: 'TODAYFILINGS Online Sole Proprietorship Registration',
    points: [
      `Individual’s Investment`,
      `No Sharing Of Profit Or Loss`,
      `Ownership`,
      `Fewer Formalities`,
      `Power Control`,
      `Continual Obligations`,
    ],
  };

  /* FaQ Data*/

  var SolePropFAQ = [
    {
      header: 'Who can start a sole proprietorship business structure?',
      body: [
        {
          content: `Any person who resides in India may establish a sole proprietorship.
          All that is required is for the person to open a current bank
          account in your company's name. The registration process is
          determined on the type of business you plan to launch.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is a sole proprietorship?',
      body: [
        {
          content: `A sole proprietorship is a company with only one owner and is not registered with the state,
           unlike a limited liability company (LLC) or corporation. Starting a sole proprietorship requires no
           paperwork -- all you do to create a sole proprietorship is simply go into business. Although you do not
            have to file paperwork to set up a sole proprietorship, you do still have to acquire business licenses
            and permits, just like with any other form of business. Most people use the term "DBA" which stands for
            "doing business as" to indicate a sole proprietorship.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How are sole proprietorships treated for tax purposes?',
      body: [
        {
          content: `Unlike corporations, sole proprietorships are not treated separately by the IRS. This means that any
          profit derived from your sole proprietorship is treated as your personal income and is accounted for on your
          individual tax return. Any such income is taxed to you in the year it was received.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'How long will it take to form a proprietorship?',
      body: [
        {
          content: `Typically, it takes no more than 15 days. The popularity of the
          proprietorship business form among dealers and merchants can be
          attributed in large part to this.
        </Accordion.Body>`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the benefits of a sole proprietorship firm?',
      body: [
        {
          content: `Least complex and the cheapest form of the business form are some of
          the main advantages of this kind of firm.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `What are the requirements needed to start a Sole Proprietorship
        Firm?`,
      body: [
        {
          content: `Name and a place for your business are only two basic requirements
          to start a Sole Proprietorship Firm.`,
          icon: false,
        },
      ],
    },
    {
      header: `Do I need an attorney to help me start a sole proprietorship?`,
      body: [
        {
          content: `Not necessarily. But every business is unique and there may be circumstances where
          a partnership, LLC, or some other kind of business structure is a better fit. You also may want
          to get more insight into the specific liabilities your sole proprietorship may face. Learn more by
          contacting a business organizations  to practice in your state.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `What are the other requirements needed along with a current bank
      account?`,
      body: [
        {
          content: `If the government-imposed threshold for your business turnover is
          exceeded, you must register for GST in addition to having an active
          bank account.`,
          icon: false,
        },
      ],
    },
    {
      header: `Do I have to register my business name?`,
      body: [
        {
          content: `Yes, unless you are using your personal name as the name of a sole proprietorship.`,
          icon: false,
        },
      ],
    },
    {
      header: `Do I need a lawyer to start a sole proprietorship or partnership?`,
      body: [
        {
          content: `There are no legal requirements for establishing a sole proprietorship or a partnership,
           other than to register the business name. It is always recommended to consult with a lawyer to ensure
           that you are structuring your business appropriately for your current and future business needs and that
            you are aware of all your legal obligations.`,
          icon: false,
        },
      ],
    },
  ];

  /* Tabs Data */
  const SoleTabsData = {
    id: 'features',
    heading: 'The registration process of Sole Proprietorship Firm in India',
    paragraph: `The following options for sole proprietorship registration
    online in India are available to the prospective person,SME, GST Registration and the registration as
    per the Shop and Establishment Act under the rules and
    regulations of that particular state.`,
    body: [
      {
        tab_title: 'SME Registration',
        tab_content: [
          {
            content_title:
              'SME Registration Process for Sole Proprietorship Firm',
            content_paragraph: `According to the MSME Act's rules, anyone wishing to
          launch a firm must register as a SME (small and medium
          enterprise). The person must fill out an online
          application in order to set up a sole proprietorship. This
          is necessary yet beneficial for the business because it
          can be used when a low-interest loan is needed. The
          government has also put up a number of programmes for the
          improvement of SMEs that have been registered under the
          MSME Act.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/ndh.png',
        alt_img: 'TODAYFILINGS Online Sole Proprietorship SME-Registration',
      },
      {
        tab_title: 'Shop Registration',
        tab_content: [
          {
            content_title: 'Shop And Establishment Registration',
            content_paragraph: `The person must be the owner of a shop, which is granted
          by the municipal parties based on the number of workers or
          employees in the firm, in order to register in the Shop
          and Establishment registration procedure.`,
            points: [
              `PAN Card and Aadhar Card of the proprietor.`,
              `Passport size photographs of the proprietor.`,
              `Office proof.`,
              `Statement copy with the bank account number, branch
            address and IFSC code.`,
            ],
          },
        ],
        content_img: '/imgs/assited/statutory-compliance.png',
        alt_img: 'TODAYFILINGS Online Sole Proprietorship Shop Registration',
      },
      {
        tab_title: 'GST Registration',
        tab_content: [
          {
            content_title: 'Company GST Registration',
            content_paragraph: `Running any kind of company operations in India requires
          obtaining a GST registration. Even if the person is
          conducting business online, they still need a GST number.
          The following documents are needed for GST registration,
          which takes roughly 5 working days:.`,
            points: [
              `PAN Card and Aadhar Card of the proprietor.`,
              `Passport size photographs of the proprietor.`,
              `Office proof.`,
              `Statement copy with the bank account number, branch
            address and IFSC code.`,
            ],
          },
        ],
        content_img: '/imgs/assited/post-incorpration.png',
        alt_img: 'TODAYFILINGS Online Sole Proprietorship GST Registration',
      },
      {
        tab_title: 'Post Compliances',
        tab_content: [
          {
            content_title: 'Post Compliances',
            content_paragraph: `To submit annual tax returns by the deadline.`,
            points: [
              `If a business is registered for GST, it should file the GST.`,
              ` If the sole proprietorship is subject to tax audits, the owner
            must deduct TDS (tax deducted at source) from employee
            wages and file TDS returns.`,
            ],
          },
        ],
        content_img: '/imgs/assited/pre-incorpration.png',
        alt_img:
          'TODAYFILINGS Online Sole Proprietorship Post Compliance Registration',
      },
    ],
  };

  /* Document Section Data */
  const SprDocData = {
    id: 'spr-doc',
    heading: `Sole Proprietorship Firm Registration `,
    sub_heading: `Documents Required for Proprietorship Firm Registration in
    India`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/aadhar.png`,
        alt_tag: `Aadhar Card`,
        header: `Aadhar Card`,
        description: `The scanned copy of the prospective owner's aadhar card for
        the sole proprietorship accounting.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `Pan Card`,
        header: `Pan Card`,
        description: `PAN cards for directors and shareholders. Foreign nationals
        must have a valid passport and other necessary documents.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/passport.png`,
        alt_tag: `Identity Proof`,
        header: `Identity Proof`,
        description: `The individual would require identity proof and address
        proof for the particular given registration process.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/frim.png`,
        alt_tag: `Sole Tradership`,
        header: `Sole Tradership Firm`,
        description: `The person is required to provide some documentation proving
        his Sole Tradership details to eligible for sole proprietorship.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/register.png`,
        alt_tag: `NOC Registered Certificate`,
        header: `NOC Registered Certificate`,
        description: `The No Objection Certificate for the proprietorship, is received for necessary if
        the registered office is on privately held property.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/certificate.png`,
        alt_tag: `No Objection Certificate`,
        header: `No Objection Certificate`,
        description: `Updated documents and the NOC or No Objection Certificate
        must be received from the owners of the registered office.`,
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Sole Proprietorship'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={SoleSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={SprAboutData} />

          <Counter />

          <Service item={Sole} />

          <Document docSecData={SprDocData} />

          <GeneralTab HTabsData={SoleTabsData} />

          <Cta />

          <FAQAccord items={SolePropFAQ} />
        </main>
      </Layout>
    </div>
  );
}
